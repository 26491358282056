import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import i18n from './i18n.js';
import { NativeSettings } from 'capacitor-native-settings';

export default () => {};

const { alert, confirm } = window;

const callbackIds = [];

const platform = Capacitor.getPlatform();

if (platform !== 'web') {
  Object.defineProperty(navigator, 'geolocation', {
    value: {
      getCurrentPosition (success, error, options) {
        Geolocation.checkPermissions().then(results => {
          const { location, coarseLocation } = results;

          if (location === 'denied' && coarseLocation === 'denied') {
            if (confirm((i18n('geolocationDenied')))) {
              NativeSettings.open({
                optionAndroid: 'location',
                optionIOS: 'locationServices'
              });
            }
            error(i18n('geolocationDenied'));
          }
        }).catch(err => {
          alert(i18n('geolocationDenied'));
          error(new Error(err));
          error = () => {};
        });
        Geolocation.getCurrentPosition(options).then(position => {
          success(position);
        }).catch(err => {
          error(new Error(err));
          error = () => {};
        });
      },
      watchPosition (success, error, options) {
        Geolocation.checkPermissions().then(results => {
          const { location, coarseLocation } = results;

          if (location === 'denied' && coarseLocation === 'denied') {
            if (confirm((i18n('geolocationDenied')))) {
              NativeSettings.open({
                optionAndroid: 'location',
                optionIOS: 'locationServices'
              });
            }
            error(i18n('geolocationDenied'));
          }
        }).catch(err => {
          alert(i18n('geolocationDenied'));
          error(new Error(err));
          error = () => {};
        });
        const currentCallbackIndex = callbackIds.length;
        callbackIds.push(null);
        Geolocation.watchPosition(options, success).then(callbackId => {
          callbackIds[currentCallbackIndex] = callbackId;
        }).catch(err => {
          error(new Error(err));
          error = () => {};
        });
        return currentCallbackIndex;
      },
      clearWatch (id) {
        const callbackId = callbackIds[id];

        Geolocation.clearWatch({ id: callbackId });
      }
    }
  });
}
