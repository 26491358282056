import { setItem } from "./storage.js";
import { Preferences } from "@capacitor/preferences";

const { redom } = window;

const { el, list } = redom;

export default class Terms {
  constructor({ i18n, api }) {
    this.i18n = i18n;
    this.el = el(
      "#terms",
      el(".bg"),
      el(
        ".center",
        el(
          ".container",
          (this.terms = el(".terms")),
          (this.chooseLanguage = list("select.choose-language", Option)),
          (this.ok = el(".button.ok")),
        ),
      ),
    );
    this.ok.onclick = async () => {
      await Preferences.set({
        key: "termsRead",
        value: i18n("__checksums.TERMS"),
      });
      api.termsRead = true;
      api.update();
    };
    this.chooseLanguage.el.onchange = async () => {
      i18n.LANG = this.chooseLanguage.el.value;
      api.update();
      try {
        await setItem("lang", i18n.LANG);
      } catch (err) {
        console.error(err);
      }
    };
  }

  update(api) {
    const { i18n } = this;
    const { LANGUAGES } = window.ENV;

    this.terms.innerHTML = i18n("TERMS");
    this.chooseLanguage.update(
      LANGUAGES.split(",")
        .map((str) => str.trim())
        .map((value) => {
          return { value, text: i18n(`lang.${value}`) };
        }),
    );
    this.chooseLanguage.el.value = i18n.LANG;
    this.ok.textContent = i18n("terms.accept");
  }
}

class Option {
  constructor() {
    this.el = el("option");
  }

  update(data) {
    const { value, text } = data;

    this.el.value = value;
    this.el.text = text;
  }
}
