import { post } from "./request.js";

const { confirm, redom } = window;

const { el, list } = redom;

export const alertTypes =
  "OPERATION_ROGUE LAND_IMMEDIATELY CONTACT_THE_TOWER CAUTION_TRAFFIC_NEARBY CONFIRM_DRONE_LANDED OTHER_SEE_FREE_TEXT LAND_IMMEDIATELY_AND_CONFIRM_DRONE_LANDED OPERATION_OUT_OF_TOWER OPERATION_NONCONFORMING OPERATION_CONFORMING OPERATION_PLAN_STATE_CHANGED OPERATION_PLAN_PROPOSED"
    .split(" ")
    .map((str) => {
      return [str, str[0] + str.slice(1).replace(/_/g, " ").toLowerCase()];
    })
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

export default class Notifications {
  constructor({ api, i18n }) {
    this.api = api;
    this.i18n = i18n;
    this.el = list("#notifications", Notification, "_id", { api, i18n });
  }

  update(api) {
    this.el.update(api.notifications);
  }
}

class Notification {
  constructor({ api, i18n }) {
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      ".notification",
      el(
        ".notification-content",
        el(
          "table",
          el(
            "tr",
            el(
              "td",
              (this.icon = el(
                ".notification-icon",
                (this.iconElement = el("i.ti.ti-alert-triangle"))
              ))
            ),
            el(
              "td",
              (this.title = el(".notification-title")),
              (this.text = el(".notification-text"))
            ),
            el("td", (this.ok = el("button.button")))
          )
        )
      )
    );
  }

  remove() {
    const { _id } = this.data;

    const removeAnimation = this.el.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 1000,
      iterations: 1,
      fill: "both",
      timingFunction: "ease-in-out",
    });

    removeAnimation.onfinish = () => {
      for (let i = 0; i < this.api.DATA.alerts.length; i++) {
        const alert = this.api.DATA.alerts[i];

        if (alert._id === _id) {
          this.api.DATA.alerts.splice(i--, 1);
        }
      }
      this.api.update();
    };
  }

  update(data) {
    const { i18n } = this;
    const { _id, severity, state, source, type, text } = data;
    const { HOST } = window.ENV;

    const DATA = this.api.DATA;

    const humanType = i18n(`notifications.type.${type}`, alertTypes[type]);
    const humanSeverity = i18n(`notifications.severity.${severity}`, severity);

    if (state === "OPERATION_PLAN_APPROVED_MANUALLY") {
      this.iconElement.className = "ti ti-check";
    } else {
      this.iconElement.className = "ti ti-alert-triangle";
    }

    if (severity === "AUTO_ACKNOWLEDGE") {
      this.ok.parentNode.style.display = "none";
      setTimeout(() => {
        this.remove();
      }, 5000);
      this.title.textContent = humanType;

      const split = text.split(" –> ");

      if (split.length > 1) {
        const from = split[0];
        const to = split[1];

        const humanFrom = i18n(`operationplan.state.${from}`, from);
        const humanTo = i18n(`operationplan.state.${to}`, to);

        this.text.textContent = `${humanFrom} –> ${humanTo}`;
      } else {
        this.text.textContent = text;
      }
    } else if (severity === "REQUIRE_OK") {
      this.ok.parentNode.style.display = "";
      this.ok.textContent = i18n("ok");
      this.ok.onclick = () => {
        this.remove();
      };
      this.title.textContent = humanType;
      const split = text.split(" –> ");

      if (split.length > 1) {
        const from = split[0];
        const to = split[1];

        const humanFrom = i18n(`operationplan.state.${from}`, from);
        const humanTo = i18n(`operationplan.state.${to}`, to);

        this.text.textContent = `${humanFrom} –> ${humanTo}`;
      } else {
        this.text.textContent = text;
      }
    } else {
      this.ok.parentNode.style.display = "";
      this.title.textContent =
        humanSeverity + (humanType ? `: ${humanType}` : "");
      this.text.textContent = text;

      if (source === "AVIA") {
        this.ok.textContent = i18n("emergency.end");
      } else {
        this.ok.textContent = i18n("acknowledge");
      }
      this.ok.onclick = async () => {
        if (source === "AVIA") {
          if (!confirm(i18n("emergency.confirmEnd"))) {
            return;
          }
        }
        try {
          await post(`${HOST}utm/alert/acknowledge/${_id}`);
        } catch (err) {
          console.error(err);
        }

        for (let i = 0; i < DATA.alerts.length; i++) {
          const alert = DATA.alerts[i];

          if (alert._id === _id) {
            alert.acknowledged = true;
            this.api.update();
          }
        }
      };
    }

    this.data = data;
  }
}
