import WeatherBox from "./weatherbox.js";

const { redom } = window;
const { el, place, mount } = redom;

let gridInitialized;

export default class WeatherControl {
  constructor({ app, api, i18n }) {
    const { THEME } = window.ENV;
    this.state = 0;
    this.i18n = i18n;
    this.altitude = 10;
    this.app = app;
    this.api = api;
    this._container = el(
      ".maplibregl-ctrl.maplibregl-ctrl-group",
      (this.button = el("button.maplibregl-ctrl-weather", el("i.ti.ti-wind"))),
    );
    this.button.onclick = () => {
      if (this.state === 1) {
        if (THEME === "eans") {
          this.map.setLayoutProperty(
            "weather-observations",
            "visibility",
            "visible",
          );
        }
        this.map.setLayoutProperty("clouds", "visibility", "visible");
        this.button.classList.add("active");
        this.weatherBoxPlace.update(false);
        this.state = 2;
      } else if (this.state === 2) {
        if (THEME === "eans") {
          this.map.setLayoutProperty(
            "weather-observations",
            "visibility",
            "none",
          );
        }
        this.map.setLayoutProperty("clouds", "visibility", "none");
        this.button.classList.remove("active");
        this.weatherBoxPlace.update(false);
        this.state = 0;
      } else {
        if (THEME === "eans") {
          this.map.setLayoutProperty(
            "weather-observations",
            "visibility",
            "visible",
          );
        }
        this.map.setLayoutProperty("clouds", "visibility", "visible");
        this.button.classList.add("active");
        this.weatherBoxPlace.update(THEME === "eans");
        this.state = 1;
      }

      const { WIND_GRID_ENABLED, METARS } = window.ENV;

      if (WIND_GRID_ENABLED) {
        this.initGrid();
        if (gridInitialized) {
          if (this.state === 1) {
            this._container.appendChild(
              (this.altitudeSelect = el(
                ".altitude-select",
                (this.altitudeRange = el("input", {
                  type: "range",
                  min: 10,
                  max: 100,
                  value: 10,
                })),
                (this.altitudeValue = el("span", "10 m")),
              )),
            );
            this.altitudeRange.oninput = () => {
              const { value } = this.altitudeRange;
              const closest = [10, 30, 50, 80, 100].sort((a, b) => {
                const aDiff = Math.abs(value - a);
                const bDiff = Math.abs(value - b);

                return aDiff - bDiff;
              });
              const altitude = closest[0];
              this.altitudeValue.textContent = `${altitude} m`;
              if (this.altitude !== altitude) {
                this.altitude = altitude;
                this.bbox = null;
                this.map.resize();
              }
            };
            this.map.setLayoutProperty("wind-grid", "visibility", "visible");
          } else {
            if (this.altitudeSelect) {
              this._container.removeChild(this.altitudeSelect);
              this.altitudeSelect = null;
            }
            this.map.setLayoutProperty("wind-grid", "visibility", "none");
          }
        }
      }

      if (METARS) {
        if (this.state === 1 || this.state === 2) {
          this.map.setLayoutProperty("metars", "visibility", "visible");
        } else {
          this.map.setLayoutProperty("metars", "visibility", "none");
        }
      }
    };
  }

  onAdd(map) {
    const { i18n } = this;
    this.map = map;

    mount(document.body, (this.weatherBoxPlace = place(WeatherBox, { i18n })));

    return this._container;
  }

  initGrid() {
    const { THEME, BASE_URL, RELATIVE_PATH } = window.ENV;
    if (!gridInitialized) {
      if (this.state !== 1) {
        return;
      }
      this.map.addSource("wind-grid", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
      this.map.addLayer(
        {
          id: "wind-grid",
          source: "wind-grid",
          type: "symbol",
          paint: {
            "text-color": [
              "step",
              ["get", "windSpeed"],
              "black",
              5,
              "DarkOrange",
              10,
              "FireBrick",
            ],
          },
          layout: {
            "icon-image": "arrow",
            "icon-rotate": {
              type: "identity",
              property: "windDirection",
            },
            "icon-rotation-alignment": "map",
            "icon-size": 0.675,
            "text-font": ["Inter Semibold"],
            "text-field": "{windSpeed} m/s",
            "text-optional": true,
            "text-offset": [0, 1.5],
            "text-size": {
              stops: [
                [7, 8],
                [9, 11],
              ],
            },
          },
        },
        THEME === "eans" ? "weather-observations" : "telemetry-symbols",
      );
      gridInitialized = true;
      this.map.on("idle", async () => {
        if (this.state === 1) {
          const bounds = this.map.getBounds();
          const [topleft, bottomright] = bounds.toArray();
          const [topleftLng, topleftLat] = topleft;
          const [bottomrightLng, bottomrightLat] = bottomright;
          const bbox = [
            topleftLng,
            topleftLat,
            bottomrightLng,
            bottomrightLat,
          ].join(",");

          if (bbox === this.bbox) {
            return;
          }

          this.bbox = bbox;

          const grid = await fetch(
            `${BASE_URL}${RELATIVE_PATH}weather/wind?alt=${this.altitude}&bbox=${bbox}`,
          )
            .then((res) => {
              if (res.ok) {
                return res;
              }
              throw new Error(res.status);
            })
            .then((res) => res.json());

          this.map.getSource("wind-grid").setData(grid);
        }
      });
    }
  }
}
