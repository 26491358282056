import Api from "./api/index.js";
import App from "./app.js";
import CapacitorSetup from "./capacitor.js";
import i18n from "./i18n.js";
import { JailbreakRoot } from "@basecom-gmbh/capacitor-jailbreak-root-detection";
import { getItem } from "./storage.js";

const { ENV, redom } = window;
const { setChildren } = redom;

const api = new Api({ i18n });
const app = new App({ api, i18n });

i18n.load().then(async () => {
  api.update();

  const termsRead = await getItem("termsRead");
  api.termsRead =
    !i18n("TERMS") ||
    i18n("TERMS") === "TERMS" ||
    termsRead === i18n("__checksums.TERMS");

  const bannerAcknowledged = await getItem("bannerAcknowledged");
  api.bannerAcknowledged =
    !i18n("BANNER") ||
    i18n("BANNER") === "BANNER" ||
    bannerAcknowledged === i18n("__checksums.BANNER");

  api.update();
});

api.app = app;
api.capacitor = new CapacitorSetup(api);

window.api = api;

api.update();

setChildren(document.body, app);

if (ENV.THEME === "austrocontrol" || (!ENV.THEME && api.query.austrocontrol)) {
  document.body.classList.add("austrocontrol");
} else if (ENV.THEME === "eans" || (!ENV.THEME && api.query.eans)) {
  document.body.classList.add("eans");
} else if (ENV.THEME === "on" || (!ENV.THEME && api.query.on)) {
  document.body.classList.add("on");
}

(async () => {
  try {
    const { result: isRooted } = await JailbreakRoot.isJailbrokenOrRooted();

    if (isRooted) {
      document.body.textContent = "Device is rooted.";
    }
  } catch (err) {
    console.error(err);
  }
})();
