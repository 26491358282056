const { redom } = window;
const { el, place, mount } = redom;

export default class WMSDemoControl {
  constructor({ app, api, i18n }) {
    this.state = 0;
    this.i18n = i18n;
    this.app = app;
    this.api = api;
    this._container = el(
      ".maplibregl-ctrl.maplibregl-ctrl-group",
      (this.button = el(
        "button.maplibregl-ctrl-weather",
        (this.icon = el("i.ti.ti-pennant-off")),
      )),
    );
    this.button.onclick = () => {
      if (this.state === 1) {
        this.map.setLayoutProperty("wms", "visibility", "none");
        this.icon.className = "ti ti-pennant-off";
        this.state = 0;
      } else {
        this.map.setLayoutProperty("wms", "visibility", "visible");
        this.icon.className = "ti ti-pennant";
        this.state = 1;
      }
    };
  }

  onAdd(map) {
    this.map = map;

    return this._container;
  }
}
