import { getItem } from "./storage.js";

const { fetch } = window;

let phrases = {};

let LANG;

export default function i18n(phrase, defaultPhrase) {
  if (!i18n.loaded) {
    return "";
  }

  const translations = phrases[phrase];

  if (!translations) {
    return phrase;
  }

  const translation = translations[LANG];

  if (!translation) {
    return translations.en || defaultPhrase || phrase;
  }

  return translation;
}

i18n.load = () => {
  const { HOST } = window.ENV;

  return new Promise((resolve, reject) => {
    fetch(`${HOST}i18n`)
      .then((res) => {
        if (!res.ok) reject(new Error(res.status));
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        phrases = res;
        i18n.loaded = true;
        resolve();
      });
  });
};

Object.defineProperty(i18n, "LANG", {
  get() {
    const { DEFAULT_LANG } = window.ENV;
    return LANG || DEFAULT_LANG || "en";
  },
  set(lang) {
    LANG = lang;
  },
});
(async () => {
  try {
    const lang = await getItem("lang");
    if (lang) {
      i18n.LANG = lang;
    }
  } catch (err) {
    console.error(err);
  }
})();
