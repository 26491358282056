import { post } from "./request.js";
import { v1 as uuidv1 } from "uuid";

const { redom } = window;
const { el } = redom;

const states = {
  PROPOSED: "Proposed",
  APPROVED: "Approved",
  TAKEOFFREQUESTED: "Takeoff requested",
  ACTIVATED: "Activated",
  CLOSED: "Closed",
};

export default class Feature {
  constructor({ api, i18n }) {
    this.i18n = i18n;
    this.api = api;
    this.el = el(
      ".info-feature",
      el(
        "table.table",
        el(
          "tr",
          el("td.minwidth", (this.legend = el(".legend"))),
          el("td", (this.name = el(".name")), (this.state = el(".tag"))),
          el("td", (this.alert = el("button.button.red.small", "Alert"))),
          el(
            "td",
            (this.requestTakeoff = el(
              "button.button.small",
              "Request takeoff",
            )),
          ),
          el(
            "td",
            (this.terminatePlan = el("button.button.red.small", "Terminate")),
          ),
        ),
      ),
    );
    const { user } = this.api.query;
    if (api.app.map.marker) {
      const { lng, lat } = api.app.map.marker.getLngLat();

      this.alert.onclick = async () => {
        const plan = JSON.parse(this.data.properties.originalPlan);
        const alert = {
          free_text: "string",
          history: [
            {
              state: "NOT_ACKNOWLEDGED",
              time_stamp: new Date(),
            },
          ],
          location: JSON.stringify({ type: "Point", coordinates: [lng, lat] }),
          message_id: uuidv1(),
          operation_plans: [plan.operationPlanId],
          severity_type: "ALERT",
          source: "AVIA",
          time_sent: new Date(),
          type: "OTHER_SEE_FREE_TEXT",
          uss_name: user || "aviagof2",
        };
        try {
          await post(
            `https://flyk.com/utmdemo/sendalert?user=${user || "aviagof2"}`,
            {
              body: JSON.stringify(alert),
            },
          );
          this.api.app.map.reloadJSON("alerts");
        } catch (err) {
          return console.error(err);
        }
      };

      this.requestTakeoff.onclick = async () => {
        const plan = JSON.parse(this.data.properties.originalPlan);
        const version = uuidv1();
        plan.state = "TAKEOFFREQUESTED";
        plan.version = version;
        try {
          await post(
            `https://flyk.com/utmdemo/operationplan/notify2?user=${user || "aviagof2"}`,
            {
              body: JSON.stringify(plan),
            },
          );
          this.api.createPlan = false;
          this.api.update();
          this.api.app.map.reloadJSON("operationplans");
        } catch (err) {
          return console.error(err);
        }
      };

      this.terminatePlan.onclick = async () => {
        const plan = JSON.parse(this.data.properties.originalPlan);
        const version = uuidv1();
        plan.state = "CLOSED";
        plan.version = version;
        try {
          await post(
            `https://flyk.com/utmdemo/operationplan/notify2?user=${user || "aviagof2"}`,
            {
              body: JSON.stringify(plan),
            },
          );
          this.api.createPlan = false;
          this.api.update();
          this.api.app.map.reloadJSON("operationplans");
        } catch (err) {
          return console.error(err);
        }
      };
    }
  }

  update(data) {
    const { i18n } = this;
    this.data = data;
    const { properties, source } = data;
    const { name, otherReasonInfo, code, state, lower, upper } = properties;

    if (source === "operationplans") {
      if (state === "APPROVED") {
        this.requestTakeoff.style.display = "";
      } else {
        this.requestTakeoff.style.display = "none";
      }
      this.terminatePlan.style.display = "";
      this.alert.style.display = "";
    } else {
      this.requestTakeoff.style.display = "none";
      this.terminatePlan.style.display = "none";
      this.alert.style.display = "none";
    }

    if (source === "uas") {
      const applicability =
        properties.applicability &&
        typeof properties.applicability === "string" &&
        JSON.parse(properties.applicability);
      const parsedApplicability = parseApplicability(applicability);

      const { justConflict, conflictType, objectType, objectId, reason } =
        properties;

      const message = parseMessage(properties, i18n.LANG);

      if (justConflict) {
        if (
          message.includes(
            "Operation volumes cannot be VLOS when its bounding box size is larger than",
          ) ||
          message.includes("VLOS flight maximum dimensions exceeded")
        ) {
          setHTML(
            this.name,
            i18n("summary.error.VLOS_BOUNDING_BOX_SIZE_TOO_BIG"),
          );
        } else {
          if (
            conflictType === "CONFLICTED_OP" &&
            objectType === "OPERATION_PLAN"
          ) {
            setHTML(
              this.name,
              i18n(
                "operationplan.conflict.conflictedOp.operationPlan",
                message,
              ).replace("{{objectId}}", objectId),
            );
          } else {
            setHTML(this.name, message);
          }
        }
        this.legend.style.backgroundColor = window.COLORS.PROHIBITED;
        this.legend.textContent = "";
      } else {
        const text = [`<b>${name || ""}</b>`];

        if (reason) {
          text.push(`(${reason})`);
        }

        if (otherReasonInfo) {
          text.push(`<i>otherReasonInfo</i>`);
        }

        if (message) {
          text.push(message);
        }

        if (parsedApplicability) {
          text.push(parsedApplicability);
        }

        text.push(`${lower} &rarr; ${upper}`);

        setHTML(this.name, text.join("<br>"));

        const colors = window.COLORS;

        const { extendedProperties, restriction, _rejecting } = properties;
        this.legend.style.backgroundColor =
          colors[_rejecting ? "PROHIBITED" : restriction] ||
          "hsl(50, 50%, 40%)";

        try {
          const fillColor =
            extendedProperties && JSON.parse(extendedProperties).color.fill;
          this.legend.style.backgroundColor = fillColor;
        } catch (err) {
          //
        }
        this.legend.textContent = "";
      }
    } else if (source === "coordinate") {
      setHTML(this.name, `<b>${i18n("coordinate")}</b><br>${name}</b>`);
      this.legend.style.backgroundColor = "";
      setHTML(this.legend, '<i class="ti ti-map-pin-filled"></i>');
    } else if (source === "weather-observations") {
      const { text } = properties;
      const split = text.split("\n");
      const results = `<b>${split[0]}</b><br>${split.slice(1).join("<br>")}`;

      setHTML(this.name, results);
      this.legend.style.backgroundColor = "";
      setHTML(this.legend, '<i class="ti ti-wind"></i>');
    } else {
      this.name.textContent = name || code;
      this.legend.style.backgroundColor = "";
      this.legend.textContent = "";
    }
    if (states[state]) {
      this.state.textContent = states[state];
      this.state.style.display = "";
    } else {
      this.state.style.display = "none";
    }
  }
}

function parseMessage(properties, LANG) {
  try {
    const extendedProperties =
      properties.extendedProperties &&
      typeof properties.extendedProperties === "string"
        ? JSON.parse(properties.extendedProperties)
        : properties.extendedProperties;
    const { localizedMessages = [] } = extendedProperties || {};
    const messages = {};

    for (const localizedMessage of localizedMessages) {
      const { language, message } = localizedMessage;

      messages[language] = message;
      messages[language.split("-")[0]] = message;
    }

    return (
      messages[LANG] ||
      messages[LANG.split("-")[0]] ||
      messages.en ||
      properties.message
    );
  } catch (err) {
    console.error(err);
  }

  return properties.message;
}

function parseApplicability(applicability) {
  const results = [];

  try {
    (applicability || []).forEach((applicability) => {
      const { permanent, startDateTime, endDateTime, schedule } = applicability;

      if (permanent === "YES") {
        if (startDateTime && endDateTime) {
          results.push(
            `${humanDate(new Date(startDateTime))}&ndash;${humanDate(new Date(endDateTime))}: H24`,
          );
        } else {
          results.push("H24");
        }
      } else {
        try {
          const parsedSchedule = parseSchedule(schedule);

          if (startDateTime && endDateTime) {
            results.push(
              `${humanDateTime(new Date(startDateTime))} &ndash; ${humanDateTime(new Date(endDateTime))}${parsedSchedule ? `: ${parsedSchedule}` : ""}`,
            );
          } else {
            results.push(parsedSchedule);
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  } catch (err) {
    console.error(err);
  }

  return results.join("<br>");
}

function parseSchedule(schedule) {
  return schedule
    .map((item) => {
      const { day, startTime, endTime } = item;
      return `${day.join(" ")} ${humanZTimerange(startTime, endTime)}`;
    })
    .join(", ");
}

function humanZTimerange(start, end) {
  return `${start.slice(0, -1)}&ndash;${end.slice(0, -1)} UTC`;
}

function humanDate(date) {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();

  return `${d}.${m}.${y}`;
}

function humanDateTime(date) {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  const h = date.getHours();
  const min = date.getMinutes();

  return `${d}.${m}.${y} ${pad(h)}:${pad(min)}`;
}

function pad(val) {
  return `0${val}`.slice(-2);
}

function setHTML(target, html) {
  if (target._html !== html) {
    target.innerHTML = html;
    target._html = html;
  }
}

function humanTemp(temp) {
  const rounded = temp < 10 ? Math.round(temp * 10) / 10 : Math.round(temp);

  if (rounded < 0) {
    return `${rounded}°C`;
  }

  return `+${rounded}°C`;
}

function aviationDeg(deg) {
  return `${`00${deg}`.slice(-3)}°`;
}

function dewPoint(airtemperature, relativehumidity) {
  return airtemperature - (100 - relativehumidity) / 5;
}
