const { redom, ENV } = window;
const { el } = redom;

const { HOST, METARS } = ENV;

export default class WeatherBox {
  constructor({ i18n }) {
    this.i18n = i18n;
    this.el = el(
      ".weatherbox",
      (this.warnings = el(".warnings")),
      (this.day = el(".forecast-day")),
      (this.night = el(".forecast-night"))
    );
    this.updateForecast();
    this.updateWarnings();
  }

  onmount() {
    this.mounted = true;
  }

  onunmount() {
    this.mounted = false;
  }

  async updateForecast() {
    const { i18n } = this;
    try {
      let url = `${HOST}weather/ee/forecast.json`;

      if (i18n.LANG === "ee" || i18n.LANG === "et" || i18n.LANG === "et-ee") {
        url += "?lang=est";
      } else {
        url += "?lang=eng";
      }
      const forecast = await fetch(url).then((res) => {
        if (res.ok) {
          return res.json();
        }
        console.error(res.status);
        setTimeout(() => {
          if (this.mounted) {
            this.updateForecast();
          }
        }, 5000);
      });

      const { day, night, date } = forecast;
      const parsedDate = humanDate(new Date(date));
      this.day.innerHTML = `<b>${i18n("forecast")} ${parsedDate} ${i18n(
        "day"
      )}</b><br>${day}<br><br>`;
      this.night.innerHTML = `<b>${i18n("forecast")} ${parsedDate} ${i18n(
        "night"
      )}</b><br>${night}<br><br>`;
    } catch (err) {
      console.error(err);
    }
  }

  async updateWarnings() {
    const { i18n } = this;
    try {
      const { warnings } = await fetch(`${HOST}weather/ee/warnings.json`).then(
        (res) => {
          if (res.ok) {
            return res.json();
          }
          console.error(res.status);
          setTimeout(() => {
            if (this.mounted) {
              this.updateWarnings();
            }
          }, 5000);
        }
      );

      if (!warnings.length) {
        this.warnings.innerText = "";
      } else {
        if (i18n.LANG === "ee" || i18n.LANG === "et" || i18n.LANG === "et-ee") {
          this.warnings.innerHTML = `${warnings
            .map(
              (warning) =>
                `<i class="ti ti-alert-triangle-filled" style="color: hsl(45, 100%, 40%);"></i> <b>${i18n(
                  "warning"
                )} ${warning.area_est}</b><br>${warning.content_est}`
            )
            .join("<br><br>")}<br><br>`;
        } else {
          this.warnings.innerHTML = `${warnings
            .map(
              (warning) =>
                `<i class="ti ti-alert-triangle-filled" style="color: hsl(45, 100%, 40%);"></i> <b>${i18n(
                  "warning"
                )} ${warning.area_eng}</b><br>${warning.content_eng}`
            )
            .join("<br><br>")}<br><br>`;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
}

function humanDateTime(date) {
  return `${humanDate(date)} at ${humanTime(date)}`;
}

function humanDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;

  return `${day}.${month}.`;
}

function humanTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${pad2(hours)}:${pad2(minutes)}`;
}

function pad2(val) {
  return `0${val}`.slice(-2);
}
