const { fetch, redom, maplibregl } = window;
const { el, list } = redom;

export default class Search {
  constructor({ app, api, i18n }) {
    const { HOST, TS } = window.ENV;
    this.app = app;
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      "#search",
      (this.input = el("input")),
      (this.icon = el("i.ti.ti-search")),
      (this.results = list(".results", Result, null, { app, api }))
    );
    this.input.onblur = () => {
      setTimeout(() => {
        this.results.update([]);
        this.icon.style.display = this.input.value ? "none" : "";
        this.input.type = this.input.value ? "search" : "text";
      }, 100);
      this.input.placeholder = "";
      this.input.value = "";
      document.body.classList.remove("searching");
    };
    this.input.onfocus = () => {
      document.body.classList.add("searching");
      this.input.placeholder = i18n("search");
      this.input.oninput();
      if (this.api.help) {
        this.api.help = false;
        this.api.update();
      }
    };
    this.input.oninput = () => {
      clearTimeout(this.searchTimeout);

      this.icon.style.display = this.input.value ? "none" : "";
      this.input.type = this.input.value ? "search" : "text";

      if (!this.input.value) {
        return;
      }

      if (this.app.map.marker) {
        this.app.map.marker.remove();
        this.app.map.marker = null;
      }
      this.api.features = [];
      this.api.update();

      const inputValue = this.input.value;

      this.searchTimeout = setTimeout(() => {
        if (inputValue !== this.input.value) {
          return;
        }
        this.results.update([{ display_name: "Searching..." }]);

        const split = this.input.value.toLowerCase().split(" ");

        const visited = {};

        const uasResults = fetch(`${HOST}utm/uas.geojson`)
          .then((res) => {
            if (!res.ok) throw new Error(res.status);
            return res;
          })
          .then((res) => res.json())
          .then((data) => {
            if (inputValue !== this.input.value) {
              return;
            }
            const results = data.features
              .filter((feature) => {
                const { properties } = feature;
                const { name = "", zoneId, identifier } = properties;

                const id = zoneId || identifier;

                if (visited[id]) {
                  return false;
                }

                visited[id] = true;

                for (const part of split) {
                  if (
                    !~(name || "").toLowerCase().indexOf(part) &&
                    !~(identifier || "").toLowerCase().indexOf(part)
                  ) {
                    return false;
                  }
                }

                return true;
              })
              .slice(0, 5)
              .map((result) => {
                const { geometry, properties } = result;
                const { name } = properties;
                const { coordinates } = geometry;

                return {
                  display_name: name,
                  get boundingbox() {
                    const longitudes = coordinates[0].map((coord) => coord[0]);
                    const latitudes = coordinates[0].map((coord) => coord[1]);

                    const lngMin = Math.min.apply(null, longitudes);
                    const lngMax = Math.max.apply(null, longitudes);

                    const latMin = Math.min.apply(null, latitudes);
                    const latMax = Math.max.apply(null, latitudes);

                    return new maplibregl.LngLatBounds(
                      new maplibregl.LngLat(lngMin, latMin),
                      new maplibregl.LngLat(lngMax, latMax)
                    );
                  },
                };
              });

            if (inputValue === this.input.value) {
              this.results.update(results.slice(0, 10));
            }

            return results;
          });

        const osmResults = fetch(
          `${HOST}map${TS ? `_${TS}` : ""}/search/${encodeURIComponent(
            this.input.value
          )}?lang=${i18n.LANG}`
        )
          .then((res) => {
            if (!res.ok) throw new Error(res.status);
            return res;
          })
          .then((res) => res.json())
          .then((results) => {
            const fixedResults = results.map((result) => {
              const { boundingbox } = result;

              return {
                ...result,
                get boundingbox() {
                  const bounds = new maplibregl.LngLatBounds(
                    new maplibregl.LngLat(boundingbox[2], boundingbox[0]),
                    new maplibregl.LngLat(boundingbox[3], boundingbox[1])
                  );
                  return bounds;
                },
              };
            });
            if (inputValue === this.input.value) {
              this.results.update(fixedResults.slice(0, 10));
            }
            return fixedResults;
          });

        Promise.all([uasResults, osmResults]).then(
          ([uasResults, osmResults]) => {
            if (inputValue === this.input.value) {
              const results = uasResults.concat(osmResults);
              this.results.update(results.slice(0, 10));
            }
          }
        );
      }, 500);
    };
  }

  update() {}
}

class Result {
  constructor({ app }) {
    this.app = app;
    this.el = el(".result");
  }

  update(data) {
    if (!data) {
      return;
    }
    const { boundingbox, display_name: displayName } = data;

    this.el.textContent = displayName;

    this.el.onclick = () => {
      if (boundingbox) {
        this.app.map.map.fitBounds(boundingbox);
        this.app.search.input.value = "";
        this.app.search.input.oninput();
      }
    };
  }
}
