const { redom } = window;
const { el, list } = redom;

const { STREETS_BASEMAP_DISABLE, TOPO_BASEMAP_DISABLE, AERIAL_BASEMAP_DISABLE } = window.ENV;

export default class SwitchMap {
  constructor ({ api, i18n }) {
    this.i18n = i18n;
    this.api = api;
    this.list = list('#switch-map', SwitchMapOption, null, { api });
    this.el = this.list.el;

    this.el.onmousedown = this.el.ontouchstart = (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.switchMap = true;
      this.el.classList.add('opened');
      window.addEventListener('mousedown', this);
      window.addEventListener('touchstart', this);
    };
  }

  handleEvent (e) {
    if (e.switchMap) {
      return;
    }
    this.el.classList.remove('opened');
    window.removeEventListener('mousedown', this);
    window.removeEventListener('touchstart', this);
  }

  update (data) {
    const { i18n } = this;
    this.list.update([
      { i: 0, id: '', name: i18n('basemap.default') },
      STREETS_BASEMAP_DISABLE ? null : { i: 1, id: 'streets', name: i18n('basemap.streets') },
      TOPO_BASEMAP_DISABLE ? null : { i: 2, id: 'topo', name: i18n('basemap.topo') },
      AERIAL_BASEMAP_DISABLE ? null : { i: 3, id: 'aerial', name: i18n('basemap.aerial') }
    ].filter(obj => obj));
  }
}

class SwitchMapOption {
  constructor ({ api }, data) {
    this.api = api;
    this.el = el('a', (this.name = el('span')));
  }

  update (data) {
    const { i, id, name } = data;

    this.el.className = `switch-map map${i + 1}`;

    if (this.api.basemap === id) {
      this.current = true;
      this.el.classList.add('current');
    } else {
      this.current = false;
      this.el.classList.remove('current');
    }
    this.name.textContent = name;

    this.el.onmousedown = this.el.ontouchstart = (e) => {
      if (!this.current) {
        if (id) {
          window.location.search = `?basemap=${id}`;
        } else {
          window.location.search = '';
        }
      }
    };
  }
}
